<template>
  <div>
    <v-row justify="center">
      <v-col
        xl="8"
        lg="8"
        md="8"
      >
      <v-container>
        <p class="text-h3">{{$t('terms_page.title')}}</p>

        <span class="font-weight-bold">{{$t('terms_page.lastUpdated')}}</span> <br />
        <span class="font-weight-bold">{{$t('terms_page.company')}}</span> <br />
        <span class="font-weight-bold">{{$t('terms_page.location')}}</span> <br />

        <br />

        <p class="text-h4">{{$t('terms_page.section1Title')}}</p>

        <p class="text-justify">
          {{$t('terms_page.section1Text')}}
        </p>

        <p class="text-h4">{{$t('terms_page.section2Title')}}</p>

        <p class="text-justify">
          {{$t('terms_page.section2Text')}}
        </p>

        <p class="text-h4">{{$t('terms_page.section3Title')}}</p>

        <p
          class="text-justify"
          v-html="$t('terms_page.section3Text')"
        ></p>

        <p class="text-h4">{{$t('terms_page.section4Title')}}</p>

        <p class="text-justify">
          {{$t('terms_page.section4Text')}}
        </p>

        <p class="text-h4">{{$t('terms_page.section5Title')}}</p>

        <p class="text-justify">
          {{$t('terms_page.section5Text')}}
        </p>

        <p class="text-h4">{{$t('terms_page.section6Title')}}</p>

        <p class="text-justify">
          {{$t('terms_page.section6Text')}}
        </p>

        <p class="text-h4">{{$t('terms_page.section7Title')}}</p>

        <p class="text-justify">
          {{$t('terms_page.section7Text')}}
        </p>

        <p class="text-h4">{{$t('terms_page.section8Title')}}</p>

        <p class="text-justify">
          {{$t('terms_page.section8Text')}}
        </p>

        <p class="text-h4">{{$t('terms_page.section9Title')}}</p>

        <p class="text-justify">
          {{$t('terms_page.section9Text')}}
        </p>

        <p class="text-h4">{{$t('terms_page.section10Title')}}</p>

        <p
          class="text-justify"
          v-html="$t('terms_page.section10Text')"
        ></p>

        <p class="text-justify">
          {{$t('terms_page.conclusion')}}
        </p>
      </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TermsOfUsePage",
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
